import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[upperCase]'
})
export class UpperCaseDirective {
  
    constructor(
        private elementRef: ElementRef
    ){}
  
    @HostListener('keyup') onKeyUp() {
        this.elementRef.nativeElement.value = this.elementRef.nativeElement.value.toUpperCase();    
    }
}