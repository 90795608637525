import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';

import { NgbModule, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxCurrencyModule, CurrencyMaskInputMode } from 'ngx-currency';
import { RutModule } from 'rut-chileno';
import { Ng9RutModule } from 'ng9-rut';

import { DateParserFormatter } from '@services/datepicker.service';

import { AutoFocusDirective } from '@directives/auto-focus.directive';
import { UpperCaseDirective } from '@directives/upper-case.directive';
import { SelectValueDirective } from '@directives/select-value.directive';

import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
registerLocaleData(localeEs, 'es');

export const customCurrencyMaskConfig = {
    align: "right",
    allowNegative: true,
    allowZero: true,
    decimal: ",",
    precision: 0,
    prefix: "",
    suffix: "",
    thousands: ".",
    nullable: true,
    min: null,
    max: null,
    inputMode: CurrencyMaskInputMode.FINANCIAL
};

@NgModule({
    declarations: [
        AutoFocusDirective,
        UpperCaseDirective,
        SelectValueDirective
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        ConfirmationPopoverModule.forRoot({
            popoverTitle: '',
            confirmText: 'Si',
            cancelText: 'No',
            confirmButtonType: 'success btn-sm',
            cancelButtonType: 'default btn-sm',
            placement: 'bottom',
            appendToBody: true,
            reverseButtonOrder: true
        }),
        NgxDatatableModule,
        NgSelectModule,
        NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
        RutModule,
        Ng9RutModule
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        ConfirmationPopoverModule,
        NgxDatatableModule,
        NgSelectModule,
        AutoFocusDirective,
        UpperCaseDirective,
        SelectValueDirective,
        NgxCurrencyModule,
        RutModule,
        Ng9RutModule
    ],
    providers: [
        {
            provide: NgbDateParserFormatter,
            useValue: new DateParserFormatter("DD/MM/YYYY") // <== format!
        },
        DatePipe
    ]
})
export class CompartidosModule { }
