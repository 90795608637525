import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[selectValue]'
})
export class SelectValueDirective {
  
    constructor(
        private elementRef: ElementRef
    ){}
  
    @HostListener('focus') onFocus() {
        this.elementRef.nativeElement.select();    
    }

    @HostListener('mouseup', ['$event']) onMouseUp(e: any) {
        e.preventDefault();
    }
}