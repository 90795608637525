import { Injectable, OnDestroy } from "@angular/core";
import { Subject, BehaviorSubject, fromEvent } from "rxjs";
import { takeUntil, debounceTime } from "rxjs/operators";
import { Router } from "@angular/router";

// Menu
export interface Menu {
	headTitle1?: string,
	headTitle2?: string,
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
	modulo?: number;
	seccion?: number;
}

@Injectable({
    providedIn: "root",
})
export class NavService implements OnDestroy {
    private unsubscriber: Subject<any> = new Subject();
    public screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

    // Search Box
    public search: boolean = false;

    // Language
    public language: boolean = false;

    // Mega Menu
    public megaMenu: boolean = false;
    public levelMenu: boolean = false;
    public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

    // Collapse Sidebar
    public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

    // For Horizontal Layout Mobile
    public horizontal: boolean = window.innerWidth < 991 ? false : true;

    // Full screen
    public fullScreen: boolean = false;

    constructor(private router: Router) {
        this.setScreenWidth(window.innerWidth);
        fromEvent(window, "resize")
        .pipe(debounceTime(1000), takeUntil(this.unsubscriber))
        .subscribe((evt: any) => {
            this.setScreenWidth(evt.target.innerWidth);
            if (evt.target.innerWidth < 991) {
            this.collapseSidebar = true;
            this.megaMenu = false;
            this.levelMenu = false;
            }
            if (evt.target.innerWidth < 1199) {
            this.megaMenuColapse = true;
            }
        });
        if (window.innerWidth < 991) {
        // Detect Route change sidebar close
        this.router.events.subscribe((event) => {
            this.collapseSidebar = true;
            this.megaMenu = false;
            this.levelMenu = false;
        });
        }
    }

    ngOnDestroy() {
        // this.unsubscriber.next();
        this.unsubscriber.complete();
    }

    private setScreenWidth(width: number): void {
        this.screenWidth.next(width);
    }

    MENUITEMS: Menu[] = [
        {
            path: '/app/inicio',
            title: 'Inicio',
            icon: 'fa-home',
            type: 'link',
            active: false
        },

        {
            path: '/app/asistencias',
            title: 'Registro de Asistencias',
            icon: 'fa-tasks',
            type: 'link',
            active: false,
            seccion: 1
        },
        {
            path: '/app/membresias',
            title: 'Membresías',
            icon: 'fa-certificate',
            type: 'link',
            active: false,
            seccion: 2
        },
        {
            path: '/app/planes',
            title: 'Planes',
            icon: 'fa-navicon',
            type: 'link',
            active: false,
            seccion: 3
        },
        {
            title: 'Personas',
            icon: 'fa-male',
            type: 'sub',
            active: false,
            modulo: 4,
            children: [
                { 
                    path: '/app/personas/trabajadores',
                    title: 'Trabajadores',
                    type: 'link',
                    seccion: 4
                },
                { 
                    path: '/app/personas/clientes',
                    title: 'Clientes',
                    type: 'link',
                    seccion: 5
                },
                { 
                    path: '/app/personas/visitas',
                    title: 'Visitas',
                    type: 'link',
                    seccion: 6
                },
                { 
                    path: '/app/personas/cargos',
                    title: 'Cargos',
                    type: 'link',
                    seccion: 7
                }
            ]
        },
        {
            title: 'Mantenedores',
            icon: 'fa-database',
            type: 'sub',
            active: false,
            modulo: 5,
            children: [
                { 
                    path: '/app/mantenedores/regiones',
                    title: 'Regiones',
                    type: 'link',
                    seccion: 8
                },
                { 
                    path: '/app/mantenedores/ciudades',
                    title: 'Ciudades',
                    type: 'link',
                    seccion: 9
                },
                { 
                    path: '/app/mantenedores/formas-pagos',
                    title: 'Formas de Pagos',
                    type: 'link',
                    seccion: 10
                }
            ]
        },
        {
            title: 'Centro de Usuarios',
            icon: 'fa-users',
            type: 'sub',
            active: false,
            modulo: 6,
            children: [
                { 
                    path: '/app/centrousuarios/usuarios',
                    title: 'Usuarios',
                    type: 'link',
                    seccion: 11
                },
                { 
                    path: '/app/centrousuarios/bitacora',
                    title: 'Bitácora',
                    type: 'link',
                    seccion: 12
                }
            ]
        },
        {
            title: 'Configuración',
            icon: 'fa-gears',
            type: 'sub',
            active: false,
            modulo: 7,
            children: [
                { 
                    path: '/app/empresa',
                    title: 'Empresa',
                    type: 'link',
                    seccion: 13
                },
                { 
                    path: '/app/parametros',
                    title: 'Parámetros',
                    type: 'link',
                    seccion: 14
                }
            ]
        }
    ];

    // Array
    items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
}
