import { Component, ViewEncapsulation, HostListener } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Menu, NavService } from '../../services/nav.service';
import { LayoutService } from '../../services/layout.service';

declare var require;
const Swal = require('sweetalert2');

import { AccountService } from '@services/account.service';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SidebarComponent {

    public iconSidebar;
    public menuItems: Menu[];
    public url: any;
    public fileurl: any;

    // For Horizontal Menu
    public margin: any = 0;
    public width: any = window.innerWidth;
    public leftArrowNone: boolean = true;
    public rightArrowNone: boolean = false;

    constructor(
        private router: Router,
        public navServices: NavService,
        public layout: LayoutService,
        private accountService: AccountService
    ) {
        let administrador = this.accountService.esAdministrador();

        /* this.navServices.items.subscribe(menuItems => {
        this.menuItems = menuItems;
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
            menuItems.filter(items => {
                if (items.path === event.url) {
                this.setNavActive(items);
                }
                if (!items.children) { return false; }
                items.children.filter(subItems => {
                if (subItems.path === event.url) {
                    this.setNavActive(subItems);
                }
                if (!subItems.children) { return false; }
                subItems.children.filter(subSubItems => {
                    if (subSubItems.path === event.url) {
                    this.setNavActive(subSubItems);
                    }
                });
                });
            });
            }
        });
        }); */
        this.navServices.items.subscribe(menuItems => {
            let menuFinal: any[] = [];

            menuItems.forEach((item, index) => {
                if (!item.modulo && !item.seccion)
                    menuFinal.push(item);
                
                if (item.modulo) {
                    let modulo: any = this.accountService.accesoModulos(item.modulo);

                    if (item.children){ 
                        let children = item.children;
                        
                        item.children = [];

                        children.forEach((sitem, sindex) => {
                            if (sitem.seccion) {
                                let seccion: any = this.accountService.accesoSecciones(sitem.seccion);
                                if (seccion.checked || administrador)
                                    item.children.push(sitem);
                            }
                        });
                    }
                    if (modulo.checked || administrador)
                        menuFinal.push(item);                        
                }

                if (item.seccion) {
                    let seccion: any = this.accountService.accesoSecciones(item.seccion);
                    if (seccion.checked || administrador)
                        menuFinal.push(item);
                }
                
            });

            this.menuItems = menuFinal;
            this.router.events.subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    menuItems.filter(items => {
                        if (items.path === event.url) {
                            this.setNavActive(items);
                        }
                        if (!items.children) { return false; }
                        items.children.filter(subItems => {
                            if (subItems.path === event.url) {
                                this.setNavActive(subItems);
                            }
                            if (!subItems.children) { return false; }
                            subItems.children.filter(subSubItems => {
                                if (subSubItems.path === event.url) {
                                    this.setNavActive(subSubItems);
                                }
                            });
                        });
                    });
                }
            });
        });

    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.width = event.target.innerWidth - 500;
    }

    sidebarToggle() {
        this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
    }

    // Active Nave state
    setNavActive(item) {
        this.menuItems.filter(menuItem => {
        if (menuItem !== item) {
            menuItem.active = false;
        }
        if (menuItem.children && menuItem.children.includes(item)) {
            menuItem.active = true;
        }
        if (menuItem.children) {
            menuItem.children.filter(submenuItems => {
            if (submenuItems.children && submenuItems.children.includes(item)) {
                menuItem.active = true;
                submenuItems.active = true;
            }
            });
        }
        });
    }

    // Click Toggle menu
    toggletNavActive(item) {
        if (!item.active) {
        this.menuItems.forEach(a => {
            if (this.menuItems.includes(item)) {
            a.active = false;
            }
            if (!a.children) { return false; }
            a.children.forEach(b => {
            if (a.children.includes(item)) {
                b.active = false;
            }
            });
        });
        }
        item.active = !item.active;
    }


    // For Horizontal Menu
    scrollToLeft() {
        if (this.margin >= -this.width) {
        this.margin = 0;
        this.leftArrowNone = true;
        this.rightArrowNone = false;
        } else {
        this.margin += this.width;
        this.rightArrowNone = false;
        }
    }

    scrollToRight() {
        if (this.margin <= -3051) {
        this.margin = -3464;
        this.leftArrowNone = false;
        this.rightArrowNone = true;
        } else {
        this.margin += -this.width;
        this.leftArrowNone = false;
        }
    }

    logout() {
        Swal.fire({
            icon: 'question',
            title: 'Cerrar Sesión',
            text: 'Seguro de cerrar sesión.',
            showCancelButton: true,
            confirmButtonText: 'Si',
            cancelButtonText: 'No',
            customClass: 'swal2-sm'
        })
        .then((result) => {
            if (result.value) {
                this.accountService.logout();
            }
        });
    }

}
