import { TranslationWidth } from '@angular/common';
import {Injectable} from '@angular/core';
import {NgbDatepickerI18n, NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

const I18N_VALUES = {
    'es': {
        weekdays: ['Lun.', 'Mar.', 'Mie.', 'Jue.', 'Vie.', 'Sab.', 'Dom.'],
        months: ['Ene.', 'Feb.', 'Mar.', 'Abr.', 'May.', 'Jun.', 'Jul.', 'Ago.', 'Sep.', 'Oct.', 'Nov.', 'Dic.'],
        weekLabel: 'Sem.'
    }
    // other languages you would support
};

// Define a service holding the language. You probably already have one if your app is i18ned. Or you could also
// use the Angular LOCALE_ID value
@Injectable({ providedIn: 'root' })
export class I18n {
    language = 'es';
}

// Define custom service providing the months and weekdays translations
@Injectable({ providedIn: 'root' })
export class Datepickeri18n extends NgbDatepickerI18n {
    constructor(
        private _i18n: I18n
    ) {
        super();
    }

    getWeekdayLabel(weekday: number): string {
        return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
    }
    getWeekLabel(): string {
        return I18N_VALUES[this._i18n.language].weekLabel;
    }
    getMonthShortName(month: number): string {
        return I18N_VALUES[this._i18n.language].months[month - 1];
    }
    getMonthFullName(month: number): string {
        return this.getMonthShortName(month);
    }
    getDayAriaLabel(date: NgbDateStruct): string {
        return `${date.day}-${date.month}-${date.year}`;
    }
}

@Injectable({ providedIn: 'root' })
export class DateParserFormatter extends NgbDateParserFormatter {

    constructor(private momentFormat: string) {
        super();
    };

    format(date: NgbDateStruct): string {
        if (date === null) {
        return '';
        }
        const d = moment({ year: date.year,
        month: date.month - 1,
        date: date.day });
        return d.isValid() ? d.format(this.momentFormat) : '';
    }

    parse(value: string): NgbDateStruct {
        if (!value) {
        return null;
        }
        const d = moment(value, this.momentFormat);
        return d.isValid() ? { year: d.year(),
        month: d.month() + 1,
        day: d.date() } : null;
    }
}